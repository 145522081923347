export const constant = {};
// constant.baseurl = "https://cors-anywhere.herokuapp.com/https://api-alamodev.influx.co.in/tci/v1";
// constant.baseurl = "https://api-intake.drafthouse.com/server-manager/v1";
constant.baseurl = "https://apidev-malco.influx.co.in/tci-manager/v1";
//constant.fnbbaseurl = "https://api-alamodev.influx.co.in/cms/v2";
constant.socketurl = "";
// constant.socketPath = "https://api-intake.drafthouse.com/tci/v1/socket.io";
//constant.baseurl = "http://localhost:3004";
// https://cors-anywhere.herokuapp.com/

