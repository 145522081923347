import React from "react";
import { motion } from "framer-motion";
import * as API from "../configuration/apiConfig";
import { constant } from "../configuration/config";
import { IoBackspaceOutline, IoArrowForwardSharp } from "react-icons/io5";
import Homepage from "./Homepage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import CommonErrorPopup from "../components/modal/commonErrorPopup";
import { BsSearch } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import Header from "../components/Header";

class Pinpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            place: "",
            pin: ["", "", "", ""],
            pinAsterisk: ["", "", "", ""],
            redirect: "",
            numLine1: ["1", "2", "3"],
            numLine2: ["4", "5", "6"],
            numLine3: ["7", "8", "9"],
            numLine4: ["1", "0", "2"],
            loginData: [],
            accessToken: "",
            navigate: false,
            passwordInputValue: "",
            passwordAsterisk: "",
            commonErrorPoupSH: false,
            commonErrorMsg: "",
            locationDropdownJson: [],
            locationSearchInputValue: "",
            locationFilter: [],
            locationDetails: [],
            dropDownToggle: false,
        };
        this.ref0 = React.createRef();
        this.ref1 = React.createRef();
        this.ref2 = React.createRef();
        this.ref3 = React.createRef();
    }

    redirectToNextPage = async (pin) => {
        this.setState({ redirect: "" });
        if (pin !== "") {
            await API.getAccessToken(pin)
                .then(async (response) => {
                    let loginData = response;
                    localStorage.setItem("loginData", JSON.stringify(loginData));
                    this.setState({ redirect: true });
                    // console.log(loginData[0].token.accessToken, 'accessToken');
                    setTimeout(() => {
                        this.setState({ navigate: true, loginData });
                    }, 500);
                })
                .catch((e) => {
                    console.log(e.error.message);
                    this.setState({
                        redirect: false,
                        // commonErrorPoupSH: true,
                        // commonErrorMsg: e.error.message,
                    });
                });
        }
    };

    pinHandler = (value, index) => {
        this.setState({ redirect: "" });
        let passwordInputValue = this.state.passwordInputValue;
        if (passwordInputValue.length == 10) {
            this.setState({
                passwordInputValue: passwordInputValue,
            });
        } else {
            this.setState(
                {
                    passwordInputValue: passwordInputValue + value,
                },
                () => {
                    this.setState({
                        passwordAsterisk: this.maskCharacter(
                            this.state.passwordInputValue,
                            "*",
                            -10
                        ),
                    });
                }
            );
        }
    };

    onKeyDown = (e, index) => {
        this.setState({ redirect: "" });
        if (e.keyCode === 8) {
            let passwordInputValue = this.state.passwordInputValue;
            this.setState(
                {
                    passwordInputValue: passwordInputValue.slice(0, -1),
                },
                () => {
                    this.setState({
                        passwordAsterisk: this.maskCharacter(
                            this.state.passwordInputValue,
                            "*",
                            -10
                        ),
                    });
                }
            );
        }
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
        try {
            let accessToken = API.getCookie("accessToken");
            this.setState({ accessToken });
            if (accessToken === null) {
                this.ref0.current.focus();
            }
        } catch (e) {
            this.setState({ accessToken: "" });
        }
        this.locationJsonData();
    };

    loginAPICall = () => {
        var pin = this.state.passwordInputValue;
        this.redirectToNextPage(pin);
    };

    maskCharacter = (str, mask, n = 1) => {
        return ("" + str).slice(0, -n).replace(/./g, mask) + ("" + str).slice(-n);
    };

    commonError_outsideClick = () => {
        this.setState({ commonErrorPoupSH: true });
    };
    cError_closeBtn = () => {
        this.setState({ commonErrorPoupSH: false });
    };

    locationJsonData = () => {
        axios
            .get(`${constant.baseurl}/locations`)
            .then((response) => {
                console.log(response.data);
                this.setState(
                    {
                        locationDropdownJson: response.data,
                    },
                    () => {
                        this.setDropDownCookies();
                    }
                );
            })
            .catch((ex) => {
                console.log(ex.response);
            });
    };

    locationSearchInputChange = (event) => {
        var inputVal = event.target.value;
        var inputName = event.target.name;
        this.setState((prevState) => {
            var filteredLocation = prevState.locationDropdownJson.filter(
                (element) => {
                    var searchOption = element.name;
                    return searchOption.toLowerCase().includes(inputVal.toLowerCase());
                }
            );
            if (inputVal == "") {
                this.setState({
                    locationFilter: [],
                });
            }
            return {
                [inputName]: inputVal,
                locationFilter: filteredLocation,
            };
        });
    };

    locationList = (e, empData) => {
        document.cookie = "managerCinemaId=" + empData.id;
        document.cookie = "managerLocationId=" + empData.location;
        document.cookie =
            "locations=" + JSON.stringify(this.state.locationDropdownJson);
        var locationDropdownJson = this.state.locationDropdownJson;
        locationDropdownJson
            .filter((person) => person.name == empData.name)
            .map((filteredLocation) =>
                this.setState({
                    locationDetails: [filteredLocation],
                    locationSearchInputValue: `${empData.name}`,
                    locationFilter: [],
                    dropDownToggle: false,
                })
            );
    };

    downArrowBtn = () => {
        var locationDropdownJson = this.state.locationDropdownJson;
        this.setState(
            {
                dropDownToggle: !this.state.dropDownToggle,
            },
            () => {
                if (this.state.dropDownToggle) {
                    this.setState({
                        locationFilter: locationDropdownJson,
                    });
                } else {
                    this.setState({
                        locationFilter: [],
                    });
                }
            }
        );
    };

    closeBtn = () => {
        this.setState({ locationSearchInputValue: "" });
    };

    forwardArrowDisabled = () => {
        var disabled = true;
        let locationDetails = this.state.locationDetails;
        if (locationDetails.length > 0) {
            let getName = locationDetails[0].name;
            let locationSearchInputValue = this.state.locationSearchInputValue;
            if (getName == locationSearchInputValue) {
                disabled = false;
            }
        }
        return disabled;
    };

    setDropDownCookies = () => {
        var managerCinemaId = API.getCookie("managerCinemaId");
        var locId = API.getCookie("managerLocationId");
        // console.log(managerCinemaId,locId, 'managerCinemaId,locId');
        if (managerCinemaId == null && locId == null) {
            this.setState({ locationSearchInputValue: "" });
        } else {
            let locationDropdownJson = this.state.locationDropdownJson;
            if (locationDropdownJson.length > 0) {
                let getIndex = locationDropdownJson.findIndex(
                    (x) => x.id == managerCinemaId
                );
                if (getIndex != -1) {
                    let getName = locationDropdownJson[getIndex].name;
                    this.setState({
                        locationSearchInputValue: getName,
                        locationDetails: [locationDropdownJson[getIndex]],
                    });
                }
            }
        }
    };

    render() {
        let {
            navigate,
            pin,
            pinAsterisk,
            redirect,
            numLine1,
            numLine2,
            numLine3,
            loginData,
            accessToken,
            passwordInputValue,
            passwordAsterisk,
            locationDropdownJson,
            locationSearchInputValue,
            locationFilter,
        } = this.state;
        if (
            (navigate && localStorage.getItem("loginData") !== null) ||
            (accessToken !== null && accessToken !== "" && accessToken !== undefined)
        ) {
            // console.log(
            //   navigate,
            //   localStorage.getItem("loginData"),
            //   accessToken,
            //   "test"
            // );
            return <Homepage loginData={loginData} />;
        } else {
            return (
                <React.Fragment>
                    {/* <CommonErrorPopup
                        state={this.state}
                        show={this.state.commonErrorPoupSH}
                        onHide={this.commonError_outsideClick}
                        cError_closeBtn={this.cError_closeBtn}
                        backdrop="static"
                    /> */}
                    <>
                        <Header />
                        <section className="location-dropdown">
                            {locationDropdownJson.length > 0 && (
                                <section
                                    className={`search-wrap ${locationFilter.length > 0 ? "remove-radius" : ""
                                        }`}
                                >
                                    <aside className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Select location"
                                            name="locationSearchInputValue"
                                            value={locationSearchInputValue}
                                            onChange={(e) => this.locationSearchInputChange(e)}
                                            autoComplete="off"
                                        />
                                        <aside className="search-icon">
                                            <BsSearch size={25} color={"#fbfbfb"} />
                                        </aside>
                                        {/* {
                              (locationSearchInputValue != '') && (locationFilter.length == 0) &&
                              <aside className="close-icon curser-pointer" onClick={this.closeBtn}>
                                <HiX
                                  size={25}
                                  color={"#a6a6a6"}
                                />
                              </aside>
                            } */}

                                        <aside
                                            className="close-icon curser-pointer"
                                            onClick={this.downArrowBtn}
                                        >
                                            <AiFillCaretDown size={25} color={"#65b5b9"} />
                                        </aside>
                                    </aside>
                                    <aside
                                        className={`search-list-blk ${locationFilter.length > 0 ? "hg" : ""
                                            }`}
                                    >
                                        <ul>
                                            {locationFilter.map((locationData, locationIndex) => {
                                                return (
                                                    <li className="text-start" key={locationIndex}>
                                                        <p
                                                            onClick={(e) =>
                                                                this.locationList(e, locationData)
                                                            }
                                                        >
                                                            {locationData.name}
                                                        </p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </aside>
                                </section>
                            )}
                        </section>
                        <div className="row m-0">
                            <div className="col-lg-12">
                                <div className="flex-container home-page">
                                    <div className="pin-screen-1-center keypad-blk">
                                        <motion.div
                                            className="flex-container-1 password-wrap"
                                            transition={{
                                                type: "spring",
                                                duration: 1,
                                                stiffness: 400,
                                                damping: 10,
                                            }}
                                        >
                                            <div>
                                                <motion.div
                                                    className="align-otp-text"
                                                    transition={{
                                                        duration: 0.4,
                                                    }}
                                                    animate={{
                                                        x:
                                                            passwordInputValue !== "" &&
                                                                redirect !== "" &&
                                                                !redirect
                                                                ? [0, 20, 0, 20, 0]
                                                                : 0,
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        className={
                                                            passwordInputValue !== "" && redirect !== ""
                                                                ? redirect
                                                                    ? "pin-box-green"
                                                                    : "pin-box-red"
                                                                : "pin-box"
                                                        }
                                                        onChange={(e) => {
                                                            this.pinHandler(e.target.value);
                                                        }}
                                                        value={passwordAsterisk}
                                                        maxLength="10"
                                                    />
                                                </motion.div>
                                            </div>
                                        </motion.div>
                                        {/* <div className="flex-container-1 ">
                    <div className="pin-box-text">Your 4-digit PIN</div>
                    </div> */}
                                        {/* num pad row 1 */}
                                        <div className="flex-container-1">
                                            {numLine1.map((num, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            <motion.div
                                                                className="align-num-pad"
                                                                whileTap={{
                                                                    scale: 0.9,
                                                                }}
                                                            >
                                                                <button
                                                                    className="num-btn"
                                                                    onClick={() => {
                                                                        this.pinHandler(num);
                                                                    }}
                                                                >
                                                                    {num}
                                                                </button>
                                                            </motion.div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                        {/* num pad row 2 */}
                                        <div className="flex-container-1">
                                            {numLine2.map((num, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            <motion.div
                                                                className="align-num-pad"
                                                                whileTap={{
                                                                    scale: 0.9,
                                                                }}
                                                            >
                                                                <input
                                                                    type="button"
                                                                    value={num}
                                                                    className="num-btn"
                                                                    onClick={() => {
                                                                        this.pinHandler(num);
                                                                    }}
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                        {/* num pad row 3 */}
                                        <div className="flex-container-1">
                                            {numLine3.map((num, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            <motion.div
                                                                className="align-num-pad"
                                                                whileTap={{
                                                                    scale: 0.9,
                                                                }}
                                                            >
                                                                <input
                                                                    type="button"
                                                                    value={num}
                                                                    className="num-btn"
                                                                    onClick={() => {
                                                                        this.pinHandler(num);
                                                                    }}
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                        {/* num pad row 4 */}
                                        <div className="flex-container-1 ">
                                            <div>
                                                <motion.div
                                                    className="align-num-pad"
                                                    whileTap={{
                                                        scale: 0.9,
                                                    }}
                                                >
                                                    <div className="curser-pointer">
                                                        <IoBackspaceOutline
                                                            size={40}
                                                            color={"#fefbf4"}
                                                            onClick={() => {
                                                                this.onKeyDown({ keyCode: 8 });
                                                            }}
                                                        />
                                                    </div>
                                                </motion.div>
                                            </div>
                                            <div>
                                                <motion.div
                                                    className="align-num-pad"
                                                    whileTap={{
                                                        scale: 0.9,
                                                    }}
                                                >
                                                    <input
                                                        type="button"
                                                        value={0}
                                                        className="num-btn"
                                                        onClick={() => {
                                                            this.pinHandler("0");
                                                        }}
                                                    />
                                                </motion.div>
                                            </div>
                                            <div>
                                                <div className="align-num-pad">
                                                    <div
                                                        className={`curser-pointer ${this.forwardArrowDisabled() ? "disabled" : ""
                                                            }`}
                                                    >
                                                        <IoArrowForwardSharp
                                                            size={40}
                                                            color={"#fefbf4"}
                                                            onClick={() => {
                                                                this.loginAPICall();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </React.Fragment>
            );
        }
    }
}

export default function TempFunction(props) {
    return <Pinpage {...props} navigate={useNavigate()} />;
}
