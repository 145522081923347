import React, { Component } from "react";
import Header from "../components/Header";
import { Html5QrcodeScanner } from "html5-qrcode";
import Modal from "react-bootstrap/Modal";
import * as API from "../configuration/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import dummyImage from "../assets/images/download.svg";

export default class Homepage extends Component {
    constructor() {
        super();
        this.state = {
            id: "",
            cinemaId: "",
            showResultModal: false,
            loading: false,
            locationList: [],
            url: "",
            rowNumber: "",
            columnNumber: "",
            seatNumber: "",
            cinema: "",
            audi: "",
            revenueCenter: "",
            tableNo: "",
        };
    }
    componentDidMount() {
        this.startScanning();
        let imgUrl = document
            .getElementById("reader__scan_region")
            .getElementsByTagName("img")[0];
        if (imgUrl) {
            console.log("img found", imgUrl);
        }
        this.setState({
            locationList: [...JSON.parse(API.getCookie("locations"))],
        });
    }

    startScanning = () => {
        let html5QrcodeScanner = new Html5QrcodeScanner(
            "reader",
            { fps: 10, qrbox: { width: 250, height: 250 }, aspectRatio: 1.0 },
      /* verbose= */ false
        );
        html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
    };

    onScanSuccess = (decodedText, decodedResult) => {
        console.log(decodedText, "test");
        // handle the scanned code as you like, for example:
        this.setState({
            loading: true,
        });
        let paramString = decodedText.split("?")[1];
        let queryString = new URLSearchParams(paramString);
        let id = queryString.get("qr");
        let cinemaId = API.getCookie("managerCinemaId");
        this.state.locationList.forEach((item) => {
            if (item.id === cinemaId) {
                this.setState({ cinema: item.name });
            }
        });
        this.setState({
            id: id,
            cinemaId: cinemaId,
        });

        if (id && cinemaId) {
            this.setState({
                url: id,
            });
            API.callEndpoint("GET", `/qrmappings?id=${id}&cinemaId=${cinemaId}`)
                .then((res) => {
                    //console.log(res);
                    let data = res[0];
                    //console.log(data);
                    if (data) {
                        this.setState({
                            cinemaId: data.cinemaId,
                            audi: data.audiNo,
                            rowNumber: data.rowNo,
                            columnNumber: data.columnNo,
                            seatNumber: data.seatNo,
                            revenueCenter: data.revenueCenter,
                            tableNo: data.tableNo,
                        });
                    } else {
                        this.setState({
                            cinemaId: this.state.cinemaId,
                        });
                    }
                })
                .then(() => {
                    this.setState({ showResultModal: true });
                })
                .then(() => {
                    this.setState({ loading: false });
                })
                .catch((err) => {
                    //console.log(err);
                    toast.error("Error Occurred !!", {
                        position: "top-center",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                });
        }

        //Stop Scanning
        document.getElementById("html5-qrcode-button-camera-stop").click();
    };

    handleClose = () => {
        this.startScanning();
        this.setState({ showResultModal: false, url: "" });
    };

    onScanFailure = (error) => {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        //console.log(error);
    };

    updateDetails = () => {
        //Update details on save.
        API.callEndpoint(
            "POST",
            `/qrmappings?id=${this.state.id}&cinemaId=${this.state.cinemaId}`,
            {
                id: this.state.id,
                cinemaId: this.state.cinemaId,
                audiNo: this.state.audi,
                rowNo: this.state.rowNumber,
                columnNo: this.state.columnNumber,
                // revenueCenter: this.state.revenueCenter,
                tableNo: this.state.tableNo,
            }
        )
            .then((res) => {
                console.log(res);
                this.handleClose();
                setTimeout(() => {
                    toast.success("Saved Successfully !!", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }, 50);
            })
            .catch((err) => {
                console.log(err, "err in post req");
                toast.error(() => <p>{err.error}</p>, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            });
    };

    render() {
        return (
            <>
                <ToastContainer
                    position="top-center"
                    autoClose={500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    theme="colored"
                />
                <Header />
                <div className="row qr-code-scanner">
                    <div className="col-12">
                        <div id="reader"></div>
                    </div>
                </div>
                {this.state.loading ? (
                    <Loader loading={this.state.loading} />
                ) : (
                    <>
                        <Modal
                            show={this.state.showResultModal}
                            onHide={this.handleClose}
                            centered
                        >
                            <Modal.Body>
                                <div className="row qr-scanner-result">
                                    <div className="col-12">
                                        <label for="url">QR Code</label>
                                        <textarea
                                            id="url"
                                            name="url"
                                            rows="2"
                                            className="form-control"
                                            value={this.state.url}
                                            readOnly
                                        ></textarea>
                                    </div>
                                    <div className="col-12">
                                        <label for="cinema">Cinema</label>
                                        <input
                                            id="cinema"
                                            name="cinema"
                                            type="text"
                                            className="form-control"
                                            style={{ width: "100%" }}
                                            value={this.state.cinema}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label for="audi">Audi</label>
                                        <input
                                            id="audi"
                                            name="audi"
                                            type="number"
                                            className="form-control"
                                            value={this.state.audi}
                                            onChange={(e) => {
                                                this.setState({ audi: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label for="row">Row</label>
                                        <input
                                            id="row"
                                            name="row"
                                            className="form-control"
                                            value={this.state.rowNumber}
                                            onChange={(e) => {
                                                this.setState({ rowNumber: e.target.value });
                                            }}
                                        ></input>
                                    </div>
                                    <div className="col">
                                        <label for="column">Column</label>
                                        <input
                                            id="column"
                                            className="form-control"
                                            name="column"
                                            value={this.state.columnNumber}
                                            onChange={(e) => {
                                                this.setState({ columnNumber: e.target.value });
                                            }}
                                        ></input>
                                    </div>
                                    {/* <div className="col-6">
                                        <label for="seat">Seat</label>
                                        <input
                                            id="seat"
                                            name="seat"
                                            className="form-control"
                                            value={this.state.seatNumber}
                                            onChange={(e) => {
                                                this.setState({ seatNumber: e.target.value });
                                            }}
                                        ></input>
                                    </div>
                                    <div className="col">
                                        <label for="tableNo">Table</label>
                                        <input
                                            id="tableNo"
                                            name="tableNo"
                                            className="form-control"
                                            value={this.state.tableNo}
                                            onChange={(e) => {
                                                this.setState({ tableNo: e.target.value });
                                            }}
                                        ></input>
                                    </div> */}
                                    {/* <div className="col-6">
                                        <label for="revenue-center">Revenue Center</label>
                                        <input
                                            id="revenue-center"
                                            name="revenue-center"
                                            type="number"
                                            className="form-control"
                                            value={this.state.revenueCenter}
                                            onChange={(e) => {
                                                this.setState({ revenueCenter: e.target.value });
                                            }}
                                        />
                                    </div> */}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn" onClick={this.handleClose}>
                                    Close
                                </button>
                                <button
                                    className="btn primary-btn"
                                    onClick={this.updateDetails}
                                >
                                    Save
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </>
        );
    }
}
