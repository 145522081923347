import axios from "axios";
import { constant } from "./config";
import { Buffer } from "buffer";
var MethodType, URL, Data;

export async function callEndpoint(methodType, url, data) {
  MethodType = methodType;
  URL = url;
  Data = data;
  return new Promise((resolve, reject) => {
    axios({
      url: constant.baseurl + URL,
      method: methodType,
      headers: {
        Authorization: "Bearer " + getCookie("accessToken"),
        dataversion: "en-US",
        appplatform: "WEBSITE",
        appversion: "1.0.0",
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (ex) => {
        if (ex.response.data.message !== undefined) {
          if (ex.response.data.message.toLowerCase() === "unauthorized") {
            await getRefreshToken().then(async (response) => {
              await callEndpoint(MethodType, URL, Data)
                .then((response) => {
                  resolve(response);
                })
                .catch(reject);
            });
          } else {
            reject({ error: ex.response.data.message });
          }
        } else {
          reject({ error: ex.response.data.message });
        }
      });
  });
}

export async function callFNBEndpoint(methodType, url, data) {
  MethodType = methodType;
  URL = url;
  Data = data;

  return new Promise((resolve, reject) => {
    axios({
      url: constant.fnbbaseurl + URL,
      method: methodType,
      headers: {
        Authorization: "Bearer " + getCookie("accessToken"),
        dataversion: "en-US",
        appplatform: "WEBSITE",
        appversion: "1.0.0",
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (ex) => {
        if (ex.response.data.message !== undefined) {
          if (ex.response.data.message.toLowerCase() === "unauthorized") {
            await getRefreshToken().then(async (response) => {
              await callFNBEndpoint(MethodType, URL, Data)
                .then((response) => {
                  resolve(response);
                })
                .catch(reject);
            });
          } else {
            reject({ error: ex.response.data.message });
          }
        } else {
          reject({ error: ex.response.data.message });
        }
      });
  });
}

// export async function getAccessToken(pin) {
//   return new Promise((resolve, reject) => {
//     var headerObject = {};
//     headerObject.Authorization = "Basic " + Buffer.from(pin + ":1234").toString("base64");
//     headerObject.appplatform = "WEBSITE";
//     headerObject.appversion = "1.0.0";
//     axios({
//       url: constant.baseurl + "/users/login",
//       method: "POST",
//       headers: headerObject,
//     })
//       .then((response) => {
//         document.cookie = "accessToken=" + response.data.accessToken;
//         document.cookie = "refreshToken=" + response.data.refreshToken;
//         document.cookie = "pin=" + pin;
//         resolve(response.data);
//       })
//       .catch((ex) => {
//         reject({ error: ex });
//       });
//   });
// }

export async function getAccessToken(pin) {
  var managerCinemaId = getCookie("managerCinemaId");
  return new Promise((resolve, reject) => {
    var headerObject = {};
    headerObject.Authorization =
      "Basic " + Buffer.from(`${pin}:${pin}`).toString("base64");
    headerObject.appplatform = "WEBSITE";
    headerObject.appversion = "1.0.0";
    axios({
      url: constant.baseurl + `/login?cinemaid=${managerCinemaId}`,
      method: "POST",
      headers: headerObject,
    })
      .then((response) => {
        document.cookie = "accessToken=" + response.data[0].token.accessToken;
        document.cookie = "refreshToken=" + response.data[0].token.refreshToken;
        document.cookie = "pin=" + pin;
        resolve(response.data);
      })
      .catch((ex) => {
        reject({ error: ex });
      });
  });
}

async function getRefreshToken() {
  let pin = getCookie("pin");
  var headerObject = {};
  // headerObject.Authorization = "Basic " + Buffer.from("1234:" + pin).toString("base64");
  headerObject.Authorization =
    "Basic " + Buffer.from(`${pin}:${pin}`).toString("base64");
  headerObject.appplatform = "WEBSITE";
  headerObject.appversion = "1.0.0";
  headerObject["Content-Type"] = "application/json";
  var data = {};
  data.refreshToken = getCookie("refreshToken");
  return new Promise(async (resolve, reject) => {
    axios({
      url: constant.baseurl + "/users/token/refresh",
      method: "POST",
      headers: headerObject,
      data: data,
    })
      .then((response) => {
        console.log(response, response.data.accessToken, "getRefreshToken");
        document.cookie = "accessToken=" + response.data.accessToken;
        document.cookie = "refreshToken=" + response.data.refreshToken;
        resolve(response);
      })
      .catch(async (ex) => {
        if (axios.isCancel(ex)) {
          reject({ Cancel: "" });
        } else if (
          ex.response.data.code !== 200 ||
          ex.response.data.message.toLowerCase() === "Invalid Token."
        ) {
          await getAccessToken(pin).then((response) => {
            resolve(response);
          });
        }
      });
  });
}

export function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}
