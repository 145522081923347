import React from "react";
// import LoaderSVG from "../../assets/images/loader.svg";
import { ReactComponent as LoaderSVG } from "../../assets/images/loader.svg";
class Loader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <section
        className={`pageloader ${
          this.props.loading
            ? "show d-flex justify-content-center align-items-center"
            : "d-none"
        }`}
      >
        <LoaderSVG className="loaderClass"></LoaderSVG>
      </section>
    );
  }
}

export default Loader;
