import React, { Component } from "react";
import siteLogo from "../assets/images/malco-logo.svg";

export default class Header extends Component {
  render() {
    return (
      <header className="pageHeader">
        <figure className="siteLogo">
          <img src={siteLogo} alt="alamo" className="img-fluid" width={200} />
        </figure>
      </header>
    );
  }
}
